@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.elm-time-picker-container {
  position: relative;
  width: 100%;
}

.elm-time-picker-container:before {
  display: block;
  width: 100%;
  height: 41.4px;
  content: "";
}

.elm-time-picker-inner-container {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
  list-style: none;
  font-size: 16px;
  text-align: left;
  background-color: #fff;
  border: 1px solid rgba(186, 189, 182, 1);
  border-radius: 3px;
  background-clip: padding-box;
  line-height: 1.5;
  overflow: hidden;
  left: -2px;
  width: 100%;
}

.elm-time-picker-active .elm-time-picker-inner-container {
  z-index: 1050;
}

.elm-time-picker-open-up
  .elm-time-picker-active
  .elm-time-picker-inner-container {
  top: -145px;
  display: flex;
  flex-direction: column-reverse;
}

.elm-time-picker-open-up
  .elm-time-picker-active
  .elm-time-picker-input-container {
  border-top: 1px solid #e9e9e9;
  border-bottom: 0;
}

.elm-time-picker-active .elm-time-picker-input-container {
  border-bottom: 1px solid #e9e9e9;
}

.elm-time-picker-input-container {
  box-sizing: border-box;
  position: relative;
}

.elm-time-picker-input-container input {
  margin: 0;
  border: 0;
  width: 100%;
  cursor: auto;
  line-height: 1.05;
  outline: 0;
  padding: 12px;
  background: transparent;
}

.elm-time-picker-input-container input[disabled] {
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}

.elm-time-picker-panel-clear-btn {
  display: none;
}

.elm-time-picker-input-container
  input[disabled]
  + .elm-time-picker-panel-clear-btn,
.elm-time-picker-input-container
  input[disabled]
  + .elm-time-picker-panel-clear-btn:after {
  cursor: not-allowed;
}

.elm-time-picker-panel-combobox::before,
.elm-time-picker-panel-combobox::after {
  content: " ";
  display: table;
  box-sizing: border-box;
}

.elm-time-picker-panel-select {
  float: left;
  font-size: 14px;
  border-left: 1px solid #e9e9e9;
  box-sizing: border-box;
  width: 56px;
  overflow: hidden;
  position: relative;
  height: 144px;
}

.elm-time-picker-panel-select:hover {
  overflow-y: auto;
}

.elm-time-picker-panel-select:first-child {
  border-left: 0;
  margin-left: 0;
}

.elm-time-picker-panel-select:last-child {
  border-right: 0;
}

.elm-time-picker-panel-select ul {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0 0 120px;
  width: 100%;
}

.elm-time-picker-panel-select li {
  list-style: none;
  box-sizing: content-box;
  margin: 0;
  padding: 0 0 0 8px;
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  user-select: none;
  transition: background 0.3s;
}

.elm-time-picker-panel-select li:hover {
}

.elm-time-picker-panel-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}

.elm-time-picker-panel-select
  .elm-time-picker-panel-select-option-disabled:hover {
  cursor: not-allowed;
  background: transparent;
}

.elm-time-picker-panel-select-option-selected {
  font-weight: 700;
}
